import React, { useState, useEffect } from 'react';

const LoadingSpinner = () => (
  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="flex flex-col items-center space-y-2">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      <p className="text-white text-lg">Loading more photos...</p>
    </div>
  </div>
);

const ModalPortfolio = ({ modalSrc, setModalSrc, photos, setPhotos, getPhotos }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(false);

  // Get flattened array of all images
  const getAllImages = () => Object.values(photos.pics).flat();

  // Function to handle loading more photos
  const loadMorePhotos = async () => {
    if (photos.next && !isLoading) {
      setIsLoading(true);
      setLoadingProgress(true);
      try {
        await getPhotos(photos.next);
      } finally {
        setIsLoading(false);
        // Keep loading indicator visible briefly to ensure user sees the transition
        setTimeout(() => setLoadingProgress(false), 500);
      }
    }
  };

  // Function to handle navigation
  const handleNavigation = async (direction) => {
    const allImages = getAllImages();
    const currentIndex = allImages.indexOf(modalSrc);
    
    if (direction === 'next' && currentIndex === allImages.length - 1) {
      // Load more photos if we're at the end
      await loadMorePhotos();
      const newImages = getAllImages();
      if (newImages.length > allImages.length) {
        setModalSrc(newImages[currentIndex + 1]);
      }
    } else if (direction === 'next' && currentIndex < allImages.length - 1) {
      setModalSrc(allImages[currentIndex + 1]);
    } else if (direction === 'prev' && currentIndex > 0) {
      setModalSrc(allImages[currentIndex - 1]);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = async () => {
    const diff = touchStartX - touchEndX;
    const threshold = 50; // minimum distance for swipe

    if (Math.abs(diff) > threshold) {
      if (diff > 0) {
        // Swipe left - show next image
        await handleNavigation('next');
      } else {
        // Swipe right - show previous image
        await handleNavigation('prev');
      }
    }
  };

  if (!modalSrc) return null;

  const allImages = getAllImages();
  const currentIndex = allImages.indexOf(modalSrc);

  return (
    <section
      className="modal fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={(e) => e.target.classList.contains('modal') && setModalSrc(null)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="container relative max-w-4xl mx-auto p-4">
        <button 
          className="closeModal absolute top-2 right-2 text-white hover:text-gray-300 transition-colors"
          onClick={() => setModalSrc(null)}
        >
          <i className="fa fa-close text-2xl"></i>
        </button>

        <div className="relative">
          {loadingProgress && <LoadingSpinner />}
          
          <img 
            src={modalSrc} 
            alt="" 
            className="max-h-screen object-contain mx-auto"
          />

          <button
            className="nav-btn prev absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-75 transition-opacity disabled:opacity-50"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigation('prev');
            }}
            disabled={currentIndex === 0}
          >
            <i className="fa fa-chevron-left"></i>
          </button>

          <button
            className="nav-btn next absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-75 transition-opacity disabled:opacity-50"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigation('next');
            }}
            disabled={currentIndex === allImages.length - 1 && !photos.next}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-4 py-2 rounded-full">
          {currentIndex + 1} / {allImages.length}
        </div>
      </div>
    </section>
  );
};

export default ModalPortfolio;